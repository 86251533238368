'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');
var dom = {};

var refreshCache = function() {

	dom.rows = dom.inputContainer.find('.fb-row');
	dom.cols = dom.rows.find('.fb-col');

};

var addEventHandlers = function() {

	emitter.on('fbWidget-familyfriends-field-added', function() {

		refreshCache();

	});

	emitter.on('fbWidget-familyfriends-field-removed', function() {

		refreshCache();

	});

	emitter.on('fbWidget-destroy', function() {

		dom = {};

	});

};

var setupDom = function() {

	// Setup cache.
	dom.window = $(window);
	dom.document = $(document);
	dom.html = $('html');
	dom.body = $('body');
	dom.fbForm = $('.fb-form');
	dom.headerContainer = dom.fbForm.find('.fb-header-container');
	dom.title = dom.fbForm.find('.fb-title');
	dom.cutoffMeta = dom.fbForm.find('.fb-cutoff-meta');
	dom.availableSubmissions = dom.cutoffMeta.find('.fb-cutoff-meta-available-submissions');
	dom.inputContainer = dom.fbForm.find('.fb-form-input-container');
	dom.submitBtn = dom.fbForm.find('.fb-submit-btn');
	dom.submissionContainer = dom.fbForm.find('.fb-submission-container');
	dom.submissionHeader = dom.submissionContainer.find('.fb-submission-header');
	dom.submissionMsg = dom.submissionContainer.find('.fb-submission-msg');
	dom.submissionFields = dom.submissionContainer.find('.fb-submission-fields');
	dom.submissionTime = dom.submissionContainer.find('.fb-submission-time');

	refreshCache();

};

module.exports = {

	init: function(elId) {

		setupDom();
		dom.fbForm.attr('id', elId);
		addEventHandlers();

	},

	getFieldId: function($col) {

		return parseInt($col.attr('data-field-id'), 10);

	},

	getFieldContainer: function($el) {

		return $el.closest('.fb-field-container');

	},

	getNestedFieldContainer: function($el) {

		return $el.closest('.fb-nested-field-container');

	},

	getFieldInputs: function($el) {

		return $el.find('.fb-field-input');

	},

	getFieldTitleText: function($el) {

		// Return the text of the FIRST field title we find.
		return $el.find('.fb-field-title label').html();

	},

	getNestedFieldTitleText: function($input) {

		return this.getFieldTitleText(this.getNestedFieldContainer($input));

	},

	getAntiSpamFields: function() {

		return this.fbForm.find('input[name^="formfield123456789"]');

	},

	triggerPublicFormEvent: function(eventObj) {

		dom.fbForm.triggerHandler(eventObj);

	},

	// Add accessor properties for the cache.

	get window() {

		return dom.window;

	},

	get document() {

		return dom.document;

	},

	get html() {

		return dom.html;

	},

	get body() {

		return dom.body;

	},

	get fbForm() {

		return dom.fbForm;

	},

	get headerContainer() {

		return dom.headerContainer;

	},

	get title() {

		return dom.title;

	},

	get cutoffMeta() {

		return dom.cutoffMeta;

	},

	get availableSubmissions() {

		return dom.availableSubmissions;

	},

	get inputContainer() {

		return dom.inputContainer;

	},

	get rows() {

		return dom.rows;

	},

	get cols() {

		return dom.cols;

	},

	get fieldContainers() {

		return dom.fieldContainers;

	},

	get submitBtn() {

		return dom.submitBtn;

	},

	get submissionContainer() {

		return dom.submissionContainer;

	},

	get submissionHeader() {

		return dom.submissionHeader;

	},

	get submissionMsg() {

		return dom.submissionMsg;

	},

	get submissionFields() {

		return dom.submissionFields;

	},

	get submissionTime() {

		return dom.submissionTime;

	}

};
