'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');
var imageProportion = require('image-proportion');
var throttle = require('throttle');

var dom;
var options;

var adjust = function() {

	dom.imgs.each(function(i) {

		var $img = dom.imgs.eq(i);
		var data = options.data && options.data[i] || $img.data('focal-point');
		var hasFocal = data.hasFocal;
		var isBackground = $img.is('img') ? false : true;
		var $container = isBackground ? $img : options.targetContainer ? $img.closest(options.targetContainer) : $img.parent();
		// Get the padding box width and height for the container.
		var containerWidth = $container.width() + parseFloat($container.css('padding-left')) + parseFloat($container.css('padding-right'));
		var containerHeight = $container.height() + parseFloat($container.css('padding-top')) + parseFloat($container.css('padding-bottom'));
		var cover = imageProportion.cover(data.width, data.height, containerWidth, containerHeight);
		var focalPoint;
		var distanceFromCenter;
		var adjustedOffset;
		var imgCenter;
		var targetAxis;
		var targetDirection;
		var targetDimension;
		var targetOffset;
		var cssObj;

		// console.log(i, cover);

		// Determine which axis is overflowing and can be adjusted.
		if (cover.xOverflow) {

			targetAxis = 'x';
			targetDirection = 'left';
			targetDimension = 'width';
			targetOffset = 'xOffset';

		} else {

			targetAxis = 'y';
			targetDirection = 'top';
			targetDimension = 'height';
			targetOffset = 'yOffset';

		}

		// Center the image as a baseline, unless the image is a background
		// image b/c we are assuming it will be centered via css.
		if (!isBackground) {

			// Pull the image left or up to center.
			cssObj = {
				width: cover.width,
				height: cover.height
			};

			cssObj[targetDirection] = cover[targetOffset];

			$img.css(cssObj);

		}

		// Apply the focal point.
		if (hasFocal) {

			// Determine the scaled location of the focal point.
			focalPoint = data[targetAxis] / 100 * cover[targetDimension];

			// How far from the center is the focal point?
			imgCenter = cover[targetDimension] / 2;
			distanceFromCenter = imgCenter - focalPoint;

			if (Math.abs(distanceFromCenter) > Math.abs(cover[targetOffset])) {

				// Honoring the focal point would adjust the image too much.
				// Determine which direction we are trying to go and make
				// sure the adjustment doesn't leave any whitespace.

				if (distanceFromCenter > 0) {

					// We are trying to push the image too far, don't let it go
					// past the edge.
					adjustedOffset = 0;

				} else {

					// We are trying to pull the image too far, don't let it go
					// past the edge. Adding 1 to deal with browser rounding.
					// It's better to have the focal point be off by 1px than
					// pull the image too far and have a 1px gap in the container.
					adjustedOffset = Math.ceil(cover[targetOffset] * 2) + 1;

				}

			} else {

				// We can honor the focal point.
				adjustedOffset = cover[targetOffset] + distanceFromCenter;

			}

			if (isBackground) {

				if (cover.xOverflow) {

					$img.css('background-position', adjustedOffset + 'px center');

				} else {

					$img.css('background-position', 'center ' + adjustedOffset + 'px');

				}

			} else {

				$img.css(targetDirection, adjustedOffset);

			}

		}

	});

};

var setupDom = function() {

	dom = {};

	dom.imgs = $(options.target);

};

var setupOptions = function(opts) {

	options = {
		target: '[data-focal-point]',
		targetContainer: '',
		// Allow data to be passed in or read from the DOM.
		data: null
	};

	$.extend(options, opts);

};

var addEventHandlers = function() {

	domCache.window.off('.focal-point');

	domCache.window.on('resize.focal-point', throttle(adjust, 100));

};

var init = function(opts) {

	setupOptions(opts);
	setupDom();
	addEventHandlers();
	adjust();

};

var destroy = function() {

	domCache.window.off('.focal-point');
	dom = null;
	options = null;

};

module.exports = {

	init: init,
	destroy: destroy,
	adjust: adjust

};
